import React from "react";
import * as styles from "./SemicorcheaAbiertaSvg.module.scss";
import AnimatedSvg from "../../AnimatedSvg/AnimatedSvg";
const SemicorcheaAbiertaSvg = (props) => {
  return (
    <AnimatedSvg pathClass={styles.cls1}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.49 61.17">
        <g id="Layer_2" data-name="Layer 2">
          <g data-name="Capa 1">
            <path
              className={`${styles.cls1}`}
              d="M15.23,23.69V4.61c0-.76,0-1.52,0-2.28.05-1,.34-2,1.58-1.95,1.06,0,1.43.8,1.51,1.73C18.7,5.9,21,8.5,23.65,10.89c1.62,1.45,3.24,2.92,4.78,4.46a11.7,11.7,0,0,1,3.16,12,9.06,9.06,0,0,0,0,5c1.46,5.42-.46,10.06-3.76,14.28-.54.7-1.27,1.32-2.18.69s-.56-1.53-.1-2.28A21.69,21.69,0,0,0,28,39c1.56-6.23-3.21-10.36-7.95-12-2-.71-1.63.92-1.64,2,0,7.21,0,14.43,0,21.64,0,5.62-3.19,9.28-8.72,10.08-4.09.58-7.77-1.26-8.94-4.48s.58-6.9,4.26-9a9.66,9.66,0,0,1,8-1.09c2.27.75,2.32-.22,2.31-1.93C15.2,37.36,15.23,30.52,15.23,23.69ZM6.07,57.31a13.58,13.58,0,0,0,8.31-3.83c.62-.76,1.15-1.58.66-2.57a2.26,2.26,0,0,0-2.43-1.1,12.4,12.4,0,0,0-8.22,3.95,2.13,2.13,0,0,0-.55,2.59A2.33,2.33,0,0,0,6.07,57.31Zm22.15-33c0-3.47-3.22-7.24-7.5-8.81-.56-.2-1.27-.87-1.76-.1-.22.35,0,1.1.23,1.59,1.56,3.63,4.64,5.9,7.58,8.26C27.91,26.14,28.21,25.32,28.22,24.28Z"
            />
            />
          </g>
        </g>
      </svg>
    </AnimatedSvg>
  );
};

export default SemicorcheaAbiertaSvg;
